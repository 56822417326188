<script setup lang="ts">
// components
import Buttons from '~/components/redesign/Buttons';
import SvgIcon from '~/components/SvgIcon.vue';

// composables
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// constants
import { PATHS } from '~/constants';

const localePathWithSlash = useLocalePathWithSlash();
</script>

<template>
  <div data-component-name="CTA.NewOotbis">
    <div class="info">
      <h3 v-html="$t('CTA.NewOotbis.h3')" />
      <p v-html="$t('CTA.NewOotbis.p')" />

      <Buttons.Link
        :to="localePathWithSlash(PATHS.PRODUCT.path) + '#whats-new-with-ootbi'"
        fill="outline"
        accent="purple"
        size="medium"
        target="_blank"
      >
        <span>{{ $t('CTA.NewOotbis.Link') }}</span>
        <SvgIcon name="arrow-forward" />
      </Buttons.Link>
    </div>

    <div class="image-wrapper" />
    <div class="line" />
  </div>
</template>

<style scoped lang="scss">
@use '_/mixins/flex';
@use '_/mixins/typo';
@use '_/mixins/size';
@use '_/mixins/media';
@use '_/mixins/text-transform';
@use '_/fn';

[data-component-name='CTA.NewOotbis'] {
  position: relative;
  overflow: hidden;
  padding: 4.38rem 2rem 2rem 2rem;

  @include media.tablet {
    padding: 3.19rem 1.5rem 2rem 1.5rem;
  }

  @include media.mobile {
    padding: 2rem 1rem 1.5rem 1rem;
  }

  @include flex.stretch-stretch;

  .info {
    width: 100%;

    position: relative;
    z-index: 2;

    @include flex.stretch-stretch;
    flex-direction: column;

    :deep(h3) {
      @include typo.h3;
      color: fn.token('text-primary');
      margin: 0 3.5rem 0.5rem 0;
      @include text-transform.responsive-line-breaks;

      @include media.tablet {
        margin: 0 0 0.5rem 0;
      }

      @include media.mobile {
        @include typo.h2;
        margin: 0 4rem 0.5rem 0;
      }

      span.new {
        color: fn.token('text-error');
      }
    }

    :deep(p) {
      @include typo.body-1;
      margin: 0 3.5rem 2.13rem 0;
      @include text-transform.responsive-line-breaks;

      @include media.tablet {
        @include typo.body-2;
        margin: 0 3.5rem 2.75rem 0;
      }

      @include media.mobile {
        margin: 0 4rem 3.31rem 0;
      }
    }

    [data-component-name='Buttons.Link'] {
      margin-top: auto;
      display: block;
      width: max-content;

      span {
        margin: 0 0.25rem;
      }

      svg {
        fill: fn.token('icon-primary');
        @include size.fixed(1.5rem);
        margin-right: 0.25rem;
      }

      @include media.mobile {
        width: 100%;
      }
    }
  }

  .image-wrapper {
    @include size.fixed(84.6875rem);

    position: absolute;
    top: 100%;
    left: 100%;

    transform: translate(-53.85%, -50.85%);

    background-image: url('/redesign/images/cta/new-ootbis/pic.webp');
    background-size: 100% 100%;

    @include media.tablet {
      transform: translate(-47%, -48.5%) scale(1.15) rotate(1deg);
    }

    @include media.mobile {
      transform: translate(-47.9%, -48%) scale(1.15) rotate(1deg);
    }
  }

  .line {
    position: absolute;
    right: 0.85rem;
    bottom: 0;
    z-index: 1;

    width: 4.3125rem;
    height: 19.625rem;

    background-image: url('/redesign/images/cta/new-ootbis/line-desktop.webp');
    background-size: 100% 100%;

    @include media.tablet {
      right: 0.7rem;
      bottom: -0.62rem;

      width: 4.25rem;
      height: 12.25rem;

      background-image: url('/redesign/images/cta/new-ootbis/line-tablet.webp');
    }

    @include media.mobile {
      right: 1.1rem;
      bottom: 4.5rem;

      width: 3.625rem;
      height: 12.125rem;

      background-image: url('/redesign/images/cta/new-ootbis/line-mobile.webp');
    }
  }
}
</style>
